// @ts-ignore
import baguetteBox from 'baguettebox.js';
import Swiper, { Autoplay, Navigation, Pagination } from 'swiper';

export default () => {
    function logoSwipers() {
        var sizeColumns = 5;

        if (window.matchMedia('(min-width: 993px) and (max-width: 1200px)').matches) {
            sizeColumns = 4;
        }
        if (window.matchMedia('(min-width: 601px) and (max-width: 992px)').matches) {
            sizeColumns = 3;
        }
        if (window.matchMedia('(max-width: 600px)').matches) {
            sizeColumns = 2;
        }
        if (window.matchMedia('(max-width: 400px)').matches) {
            sizeColumns = 1;
        }


        var logoSwiper = new Swiper('.swiper-container-wly', {
            modules: [Navigation, Autoplay],
            // Optional parameters
            direction: 'horizontal',
            speed: 1400,
            slidesPerView: sizeColumns,
            grabCursor: true,
            spaceBetween: 30,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            }
        });
    }

    logoSwipers();

    jQuery(window).resize(function () {
        logoSwipers();
    });
};