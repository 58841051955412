// @ts-ignore
import $, { type } from 'jquery';
import { computed, nextTick, onMounted, reactive, Ref, ref, watch } from 'vue';
import { DateTime } from 'luxon';
import {debounce} from "@/js/libraries/debounce";

export type TaxonomySlug = string;
export type Paginator = ReturnType<typeof usePaginator>;
export type PaginatorFilter = ReturnType<typeof usePaginatorFilter>;

interface FilterItems {
    [key: TaxonomySlug]: number[] | boolean;
}

interface FlattenTaxonomyFilter {
    [key: string]: string;
}

export interface PaginatorOptions {
    url: string;
    initialPage: number;
    lastPage: number;
    perPage: number;
    totalItems: number;
    searchTerm?: Ref<string|null>;
    hideFilter?: boolean;
    requestTermCounts?: boolean;
    fetch?: boolean;
    taxonomies?: Array<DiviTaxonomyDefinition | Array<DiviTaxonomyDefinition>>;
}

export interface PaginatorFilterOptions {
    paginator: Paginator;
    scrollToElementOnFilterRestore?: Ref<HTMLElement | undefined>;
    requireFilter?: boolean;
    singleFilter?: boolean;
}

export interface SelectedFilter {
    label: string;
    taxonomy: string;
    term?: number;
}

export interface DiviTermDefinition {
    term_id: number;
    taxonomy: string;
    name: string;
    slug: string;
}

export interface DiviTaxonomyDefinition {
    slug: string;
    label: string;
    terms: Array<DiviTermDefinition>;
}

/**
 * Get the URL parameters
 * source: https://css-tricks.com/snippets/javascript/get-url-variables/
 * @param  {String} url The URL
 * @return {Object}     The URL parameters
 */
const getParams = function (url) {
    const params = {};
    let parsedParams = url;
    let needsLoop = false;

    if (typeof url === 'string') {
        const query = url.split('?')[1] || '';

        parsedParams = Object.fromEntries(new URLSearchParams(query));
    }

    Object.keys(parsedParams).forEach((key) => {
        let value = parsedParams[key];

        try {
            value = JSON.parse(value);
        } catch (_) {
            if ((+value).toString() === value) {
                value = +value;
            }
        }

        // Lets decode an URL contain an array-like syntax for params
        const matches = key.match(/^(.*)\[([\dA-Za-z-_]*)\]$/);

        if (matches) {
            delete params[key];

            const currentKey = matches[2];
            const parentKey = matches[1];

            Object.assign(params, {
                [parentKey]: {
                    ...(params[parentKey] ?? {}),
                    [currentKey]: value,
                },
            });

            needsLoop = true;
        } else {
            params[key] = value;
        }
    });

    return needsLoop ? getParams(params) : params;
};

/**
 * Remove the query string from a url.
 *
 * @param {string} url
 */
const removeParams = function (url) {
    return url.split('?')[0] || '';
};

export const usePaginatorFilter = (options: PaginatorFilterOptions) => {
    const { paginator, scrollToElementOnFilterRestore = false, singleFilter = false, requireFilter = false } = options;

    const filter = ref<FilterItems>({});
    const taxonomies = paginator.taxonomies;
    const flatTaxonomies = computed(() => taxonomies.flat(1));

    const selectedFilters = computed<Array<SelectedFilter>>(() => {
        const items = [];

        flatTaxonomies.value.forEach((taxonomy) => {
            const filterItem = filter.value[taxonomy.slug];
            if (filterItem === true) {
                items.push({
                    label: taxonomy.label,
                    taxonomy: taxonomy.slug,
                });
            }

            if (Array.isArray(filterItem) && filterItem.length > 0) {
                taxonomy.terms.forEach((term) => {
                    if (filterItem.includes(term.term_id)) {
                        items.push({
                            label: term.name,
                            taxonomy: taxonomy.slug,
                            term: term.term_id,
                        });
                    }
                });
            }
        });

        return items;
    });

    const isActive = computed(() => (taxonomy: string, term?: number): boolean => {
        const value = filter.value[taxonomy];
        if (Array.isArray(value)) {
            if (!term) {
                return value.length === flatTaxonomies.value.find((t) => t.slug === taxonomy)?.terms.length;
            }

            return value.indexOf(term) >= 0;
        }

        if (typeof value === 'boolean' || value === undefined) {
            return Boolean(filter.value[taxonomy]) || false;
        }

        return false;
    });

    const countActive = computed(() => (taxonomy: string): number => {
        const value = filter.value[taxonomy];

        if (Array.isArray(value)) {
            return value.length;
        }

        if (value === true) {
            return flatTaxonomies.value.find((t) => t.slug === taxonomy).terms?.length ?? 0;
        }

        return 0;
    });

    const getSelectedTermIdsByTaxonomy = (taxonomySlug: string): number[] => {
        const items = [];

        flatTaxonomies.value.forEach((taxonomy) => {
            if (taxonomy.slug !== taxonomySlug) {
                return;
            }

            const filterItem = filter.value[taxonomy.slug];

            if (Array.isArray(filterItem) && filterItem.length > 0) {
                taxonomy.terms.forEach((term) => {
                    if (filterItem.includes(term.term_id)) {
                        items.push(term.term_id);
                    }
                });
            }
        });

        return items;
    };

    const setTaxonomyFilter = (taxonomy: string, term?: number) => {
        if (singleFilter) {
            resetFilter();
        }

        const value = filter.value[taxonomy];

        if (!term) {
            if (filter.value[taxonomy] === true) {
                filter.value[taxonomy] = false;
            } else {
                filter.value[taxonomy] = true;
            }
        } else {
            const terms = Array.isArray(value) ? value : [];
            const termIndex = terms.indexOf(term);

            if (termIndex >= 0) {
                terms.splice(termIndex, 1);
            } else {
                terms.push(term);
            }

            filter.value[taxonomy] = terms;
        }
    };

    const resetFilter = () => {
        Object.keys(filter.value).forEach((key) => delete filter.value[key]);
    };

    const applyFilter = () => {
        flatTaxonomies.value
            .map((t) => t.slug)
            .forEach((taxonomySlug) => {
                let params = null;
                const selectedItems = filter.value[taxonomySlug];

                if (selectedItems === true) {
                    params = flatTaxonomies.value
                        .find((t) => t.slug === taxonomySlug)
                        ?.terms.map((t) => t.term_id)
                        .join(',');
                } else if (Array.isArray(selectedItems) && selectedItems.length > 0) {
                    params = selectedItems.join(',');
                }

                paginator.setTaxonomyFilter(taxonomySlug, params);
            });

        saveFilterToUrlQuery();
        nextTick(() => paginator.fetchPosts());
    };

    const saveFilterToUrlQuery = () => {
        const params = new URLSearchParams(window.location.search);

        const terms = {};

        Object.keys(filter.value).forEach((slug) => {
            const value = filter.value[slug];

            if (value === true || (Array.isArray(value) && value.length > 0)) {
                terms[slug] = value;
            }
        });

        if (Object.keys(terms).length === 0) {
            params.delete('terms');
        } else {
            params.set('terms', btoa(JSON.stringify(terms)));
        }
        if (Array.from(params).length === 0) {
            history.replaceState(history.state, document.title, location.pathname);
            return true;
        }
        history.replaceState(history.state, document.title, [location.pathname, params.toString()].join('?'));
        return true;
    };

    const getFilterFromUrlQuery = () => {
        const params = new URLSearchParams(window.location.search);

        return JSON.parse(atob(params.get('terms') ?? '') || '{}');
    };

    watch(filter.value, () => applyFilter(), { deep: true });

    const urlFilter = getFilterFromUrlQuery();

    if (Object.keys(urlFilter).length > 0) {
        Object.assign(filter.value, urlFilter);

        if (scrollToElementOnFilterRestore) {
            onMounted(() => {
                $('html, body').animate({
                    scrollTop: $(scrollToElementOnFilterRestore.value).offset().top - $('header').height()
                }, 1000);
            });
        }
    } else {
        // If the filter should only have a single active filter we will pre-selected the first term.
        if (requireFilter) {
            const firstTerm =
                taxonomies
                    .flat(1)
                    .map((tax) => tax.terms)
                    .flat(1)[0] ?? null;

            if (firstTerm) {
                setTaxonomyFilter(firstTerm.taxonomy, firstTerm.term_id);
            }
        }

        applyFilter();
    }

    return {
        filter,
        paginator,
        taxonomies,
        flatTaxonomies,
        selectedFilters,
        isActive,
        countActive,
        getSelectedTermIdsByTaxonomy,
        setTaxonomyFilter,
        resetFilter,
    };
};

export const usePaginator = (options: PaginatorOptions) => {
    const {
        url, // The URL to the API of the paginator instance
        initialPage, // The initial page number (first page = 0)
        lastPage: _lastPage, // The last page number
        hideFilter = false,
        perPage = 10,
        totalItems: _totalItems,
        searchTerm = ref(),
        fetch = false,
        requestTermCounts = false,
        taxonomies = [],
    } = options;

    const loading = ref<boolean>(false);
    const page = ref<number>(initialPage);
    const lastPage = ref<number | undefined>(_lastPage);
    const totalItems = ref<number | undefined>(_totalItems);
    const taxonomyFilter = ref<FlattenTaxonomyFilter>({});
    const posts = ref([]);
    const termCounts = reactive({});
    const fromDate = ref<DateTime>(null);
    const toDate = ref<DateTime>(null);

    const nextPage = computed(() => page.value + 1);
    const fetchedAll = computed(() => page.value !== -1 && page.value >= lastPage.value);

    const getRequestedTermsCount = () =>
        taxonomies
            .flat(1)
            .map((taxonomy) => ({
                [taxonomy.slug]: taxonomy.terms.map((t) => t.term_id),
            }))
            .reduce((prev, current) => ({ ...prev, ...current }), []);

    const getCountForTerm = (taxonomy, term) => computed(() => (termCounts[taxonomy] ? termCounts[taxonomy][term] ?? -1 : -1));

    /**
     * The query params that will be used for the request to fetch more posts.
     *
     * @returns {{per_page, page: *}}
     */
    const makeQueryParams = (): any => ({
        ...getParams(url),
        per_page: perPage,
        page: nextPage.value,
        search_term: searchTerm.value,
        has_active_filter: hasActiveFilter.value,
        from: fromDate.value?.toFormat("yyyy-LL-dd"),
        to: toDate.value?.toFormat("yyyy-LL-dd"),
        ...(requestTermCounts
            ? {
                  request_terms_count: getRequestedTermsCount(),
              }
            : {}),
    });

    const hasActiveFilter = computed<boolean>(() => Object.values(taxonomyFilter.value).filter(Boolean).length > 0);

    /**
     * Set a taxonomy filter item.
     *
     * @param taxonomy
     * @param term
     */
    const setTaxonomyFilter = (taxonomy: string, term: number | number[]) => {
        const terms = (Array.isArray(term) ? term : [term]).filter((t) => !!t);

        taxonomyFilter.value[taxonomy] = terms.length > 0 ? terms.join(',') : null;

        // Setting the page to -1 forced a reset of all page settings
        page.value = -1;
    };

    const setFromDate = (date: DateTime | null) => {
        page.value = -1;
        fromDate.value = date;
    };

    const setToDate = (date: DateTime | null) => {
        page.value = -1;
        toDate.value = date;
    };

    const resetDates = () => {
        setFromDate(null);
        setToDate(null);
    };

    const setPublishedInYear = (year: number | null) => {
        if (year === null) {
            resetDates();
        } else {
            setFromDate(
                DateTime.fromObject({
                    year,
                    month: 1,
                    day: 1,
                })
            );

            setToDate(
                DateTime.fromObject({
                    year,
                    month: 12,
                    day: 31,
                })
            );
        }
    };

    /**
     * Pulls the posts for the next page and inserts them into the container, if not disabled.
     *
     * @returns {Promise<Array<any>>}
     */
    const fetchPosts = (e, reset = false) => {
        if (reset) {
            page.value = -1;

        }

        if (loading.value) {
            return Promise.resolve([]);
        }

        if (fetchedAll.value) {
            return Promise.resolve([]);
        }

        let _url = removeParams(url);
        let _queryParams = makeQueryParams();

        if (hasActiveFilter.value) {
            // Clear the params so that we can add the filter attributes
            _queryParams.terms = {};

            Object.keys(taxonomyFilter.value).forEach((name) => {
                const value = taxonomyFilter.value[name];

                if (value) {
                    _queryParams.terms[name] = value;
                }
            });
        } else if (!hideFilter) {
            // When a filter is visible, but no filter is selected we want to find posts containing any of the terms,
            // not all. This is necessary because included taxonomies/terms could be limited by the divi module.
            _queryParams['taxonomy_relation'] = 'or';
        }

        if (_queryParams.page <= 0) {
            _queryParams.min_items = Math.max(_queryParams.per_page, posts.value.length);
        }

        loading.value = true;

        return $.get(_url, _queryParams)
            .then((data) => {
                const { posts: _posts, page: _page, last_page: _last_page, total_items: _total_items } = data;

                if (page.value === -1) {
                    posts.value.splice(0, posts.value.length);
                }

                posts.value.push(..._posts);

                if (posts.value.length > 0) {
                    page.value = _page;
                }

                lastPage.value = _last_page;
                totalItems.value = _total_items;
                loading.value = false;

                if (_page <= 0) {
                    Object.assign(termCounts, data.term_counts);
                }

                return _posts;
            })
            .catch(() => {
                loading.value = false;
            });
    };

    const debouncedFetchPosts = debounce(() => fetchPosts(null, true), 200)

    onMounted(() => {
        if (fetch) {
            page.value = page.value - 1;
            void fetchPosts();
        }
    });

    watch([fromDate, toDate, searchTerm], () => {
        debouncedFetchPosts();
    });

    return {
        fetchPosts,
        taxonomies,
        setTaxonomyFilter,
        requestTermCounts,
        setFromDate,
        setToDate,
        setPublishedInYear,
        resetDates,
        loading,
        page,
        lastPage,
        totalItems,
        posts,
        fetchedAll,
        termCounts,
        getCountForTerm,
    };
};
