<template>
    <div class="posts-loader" ref="root">

        <div class="container">
            <div class="row">
                <div class="col-12 col-lg-10 col-xl-8 offset-lg-1 offset-xl-2">
                    <div v-if="faqType === 'header_mode'" class="boxes__titel">
                        <h5 v-if="subtitle">{{ subtitle }}</h5>
                        <h1>{{ title }}</h1>
                    </div>
                    <h2 v-else>{{ title }}</h2>
                </div>
            </div>
            <div v-if="faqType === 'header_mode' && search === 'on'" class="row">
                <div class="col-12 col-md-10 col-lg-8 col-xl-6 offset-md-1 offset-lg-2 offset-xl-3">
                    <div class="search__input__wrapper">
                        <div class="input-button__wrapper">
                            <div class="form-group">
                                <div class="form-floating">
                                    <input type="text" class="form-control" placeholder=" " v-model="query">
                                    <label for="search-input">FAQ & Antworten durchsuchen...</label>
                                </div>
                            </div>
                            <button type="submit" class="button button--ghost button--icon">
                                <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M9.658 2a7 7 0 1 0 0 14 7 7 0 0 0 0-14Zm-9 7a9 9 0 1 1 18 0 9 9 0 0 1-18 0Z" fill="currentColor"/><path fill-rule="evenodd" clip-rule="evenodd" d="M14.601 13.943a1 1 0 0 1 1.414 0l4.35 4.35a1 1 0 0 1-1.414 1.414l-4.35-4.35a1 1 0 0 1 0-1.414Z" fill="currentColor"/></svg>
                                <span>Suchen</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container">
            <div class="row">
                <div class="col-12 col-lg-10 col-xl-8 offset-lg-1 offset-xl-2">
                    <Buttons v-if="!hideFilter" :filter="filter"/>
                </div>
            </div>
        </div>

        <div class="bg--beige">
            <div class="container">
                <div class="post__overview">
                    <template v-if="paginator.posts.value?.length > 0">

                        <div class="wly-accordeon">
                            <div class="row">
                                <div class="col-12 col-lg-10 col-xl-8 offset-lg-1 offset-xl-2">
                                    <div v-for="post in paginator.posts.value" :key="post.id" v-html="post.content"></div>
                                </div>
                            </div>
                        </div>

                    </template>
                    <div v-else-if="paginator.loading.value">
                        laden
                    </div>
                    <div v-else-if="paginator.page.value < 0">
                        <h4 class="nothing-found">Keine FAQ gefunden.</h4>
                    </div>
                    <div v-if="paginator.posts.value.length !== 0">
                            <div v-if="loadMore && !paginator.fetchedAll.value" class="button__wrapper">
                                <div class="button__inner button__ausrichtung--center">
                                    <div
                                        class="load-more load-more-button button button--ghost"
                                        @click="paginator.fetchPosts"
                                        :disabled="paginator.loading.value"
                                    >
                                        <span v-if="paginator.loading.value">{{ $t('loading') }}</span>
                                        <span v-else>{{ $t('load_more') }}</span>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import {PropType, ref, watch, nextTick} from 'vue';
import { DiviTaxonomyDefinition, PaginatorFilter, usePaginator, usePaginatorFilter} from '@/js/vue/composition/paginator';
import Accordion from "@/js/scripts/accordion";
import Buttons from "@/js/vue/components/Filters/Buttons.vue";
import Filter from '@/js/vue/components/Filter.vue';

const props = defineProps({
    taxonomies: {
        type: Array as PropType<Array<DiviTaxonomyDefinition | Array<DiviTaxonomyDefinition>>>,
        required: true,
    },
    loadMore: {
        type: Boolean,
        default: () => true,
    },
    hideFilter: {
        type: Boolean,
        default: () => false,
    },
    paginatorOptions: {
        type: Object,
        required: true,
    },
    filterViewMode: {
        type: String,
    },
    faqType: {
        type: String,
    },
    title: {
        type: String,
    },
    subtitle: {
        type: String,
    },
    search: {
        type: String,
    },
});

const query = ref('');
const root = ref<HTMLElement>();

const paginator = usePaginator({
    ...(props.paginatorOptions as any),
    taxonomies: props.taxonomies,
    searchTerm: query,
    requestTermCounts: true,
    url: props.paginatorOptions.url + '&filterViewMode=' + props.filterViewMode,
    initialPage: -1,
    fetch: false,
    hideFilter: props.hideFilter,
});

const filter = usePaginatorFilter({
    paginator: paginator,
    scrollToElementOnFilterRestore: root,
    singleFilter: ['tabs', 'dropdown'].indexOf(props.filterViewMode) >= 0,
    requireFilter: props.filterViewMode === 'tabs',
});

watch(paginator.posts.value, () => {
  // Wait for the elements to be rendered, then apply the observer
  nextTick(() => {
    Accordion();
  });
}, {
  immediate: true
});

</script>