<template>
    <div class="filter__buttons">
        <template v-for="taxonomy in props.filter.taxonomies.flat(1)" :key="taxonomy">
            <div @click="() => { resetFilter();  }" :class="{active: countActive(taxonomy.slug) === 0}"  class="button button--ghost">
                Alle
            </div>
            <div v-for="term in taxonomy.terms"
                 :key="term"
                 class="button button--ghost"
                 :class="{active: isActive(taxonomy.slug, term.term_id)}"
                 @click="resetFilter(); setTaxonomyFilter(taxonomy.slug, term.term_id)"
            >
                {{ term.name }}
            </div>
        </template>
    </div>
</template>
<script lang="ts" setup>
import {PropType, ref} from 'vue';
import {DiviTaxonomyDefinition, PaginatorFilter} from '../../composition/paginator';
const filterVisible = ref(false);
const props = defineProps({
    filter: {
        type: Object as PropType<PaginatorFilter>,
        required: true
    }
});
const {isActive, selectedFilters, resetFilter, setTaxonomyFilter, countActive} = props.filter;

</script>